
.thankyou {
    text-align: center;
    font-size: 62px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  
  @media (max-width: 650px ) {
    .thankyou {
      font-size: 32px;
  }
}