.underline {
    text-decoration-line: underline;
}
.parent {
    border: 1px solid black;
    border-radius: 20px;
    padding: 2rem 2rem;
    width: 80%;
    margin: auto;
    height: 50%;
    margin-bottom: 10%; 
}
.child {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    width: 40%;
}
.control input,
.control textarea {
    display: block;
    font: inherit;
    border-radius: 20px;
    border: 1px solid #000;
    /* padding: 0.25rem; */
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    margin-left: 32%;
    padding-left: 20px; 
}
.control textarea {
    height: 140px;
}
.control input
{
    height: 40px;
}  
.paragraph {
    text-align: left; 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.heading {
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.control::placeholder {
   color: black;
   padding-top: 10px; 
}

.control button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    color: black;
    padding: 0.5rem 1.5rem;
    border: 1px solid #000;
    border-radius: 4px;
    margin-left: 32%;
}


@media (max-width: 650px ) {

    .heading {
        margin-top: 0px;
        font-size: 32px;
    }
    .parent {
        border: 1px solid black;
        border-radius: 20px;
        padding: 2rem 2rem;
        width: 75%;
        margin: auto;
        height: 80%;
        /* margin-bottom: 10%; */
    }
    .child {
        display: inline-block;
        padding: 1rem 1rem;
        vertical-align: middle;
        width: 80%;
    }
    
    .paragraph {
        text-align: left; 
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .control button {
        margin-left: 0;
    }
    .control input,
    .control textarea {
        margin-left: 0;
    }

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    
    .control button,
    .control input,
    .control textarea {

        margin-left: 15%;
    }

}