
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}
.container img {
    height: 39px;
}

@media (max-width: 650px ) {

    .container img {
        height: 20px;
    }
    .container {
        margin-bottom: 10%;
    }

}
    