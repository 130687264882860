.logogrid {
    border-radius: 20px;
    padding: 6rem 8rem;  
    padding-top: 2rem; 
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    width: 50%; 
    margin: auto; 
}

.container {
    display: grid;
    grid-template-columns: 15rem 15rem 15rem; 
    grid-gap: 4rem; 
    justify-content: center;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    padding-bottom: 4rem;
}

.heading { 
    text-align: center;
    font-family: Avenir;
    font-size: 52px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

@media (max-width: 650px ) {
    
    .heading { 
        text-align: center;
        width: 100%;
        font-size: 32px;
        margin-top: 0;
        font-weight: 900;
    }

    .logogrid {
        padding: 8rem 5rem;  
        padding-top: 3rem;  
        padding-bottom: 4rem;
    }
    
    .container {
        display: grid;
        grid-template-columns: 2fr 6fr;
        grid-template-rows: repeat(1, 2fr);
        grid-gap: 2rem;
        flex-basis: 100%;
    }

    .image {
        max-height: 20px;
    } 

    div > div:last-of-type {
        grid-column: 1 / -1;
    }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

    .heading { 
        text-align: center;
        width: 100%;
        font-size: 42px;
        font-weight: 900;
    }

    .logogrid {
        padding: 10rem 6rem;
        padding-top: 4rem;  
        padding-bottom: 8rem;
    }
    
    .container {
        display: grid;
        grid-template-columns: 2fr 6fr;
        grid-template-rows: repeat(1, 2fr);
        grid-gap: 4rem;
        flex-basis: 100%;
    }

    .image {
        max-height: 40px;
    }
    
    div > div:last-of-type {
        grid-column: 1 / -1;
    }

}
