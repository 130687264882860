
.header {
  padding: 5%;
  text-align: center;
}
.App{
  max-width: 1440px; 
  margin: 0 auto;
}
.App-logo {
  height: 50vmin;
  pointer-events: none;
} 

.App-header {
  min-height: 90vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

#video {
  position: fixed;
  z-index: -1;
  min-width: 100%; 
  min-height: 100%;
}
@media (max-width: 650px ) {
  
  .App-header {
    min-height: 80vh; 
  }
}
